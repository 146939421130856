import { Button, IconButton, Stack } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { OrderResponseDto, OrderStatus } from '@planning/app/api'
import { IContainerDamage } from '@planning/components'
import { NavigationStackBackButton } from '@planning/pages/TallymanV2/Components/HeaderComponents/NavigationStackBackButton'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ContainerMobile, Header, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  IInspectContainerFormData,
  InspectContainerForm,
} from '../InspectContainer/InspectContainerForm'

interface IProps {
  order?: OrderResponseDto
  damages?: IContainerDamage[]
  hasSeals?: boolean
  seals?: string[] | null
  submitButtonText: string
  onSubmit: (data: IInspectContainerFormData) => Promise<void> | void
  onDelete?: () => void
  deleteContainerDialog?: () => JSX.Element
}

export const InspectRailcarContainer: FC<IProps> = observer(
  ({
    order,
    hasSeals,
    damages,
    seals,
    submitButtonText,
    onDelete,
    onSubmit,
    deleteContainerDialog,
  }) => {
    const { t } = useTranslate()
    const { containerItemStore, appViewStore } = usePlanningStore()
    const theme = useMinimalsTheme()

    const allowContainerEditAfterTallyCheckInFF = useBooleanFlagValue(
      'allow-container-edit-after-tally-check-in',
      false,
    )

    if (order) {
      useEffect(() => {
        if (order.containerId) containerItemStore.fetchById(order.containerId)
      }, [containerItemStore, order.containerId])
    }

    const containerItem = containerItemStore.elements[order?.containerId ?? 0]

    const form = useForm<IInspectContainerFormData>({
      defaultValues: {
        id: order?.id ?? undefined,
        referenceNumber: order?.referenceNumber,
        direction: order?.direction,
        carrierType: order?.carrierVisitType,
        containerId: order?.containerId,
        containerNumber: order?.containerNumber,
        containerIsoCode: order?.containerIsoCode,
        containerTare: order?.containerTare,
        containerMaxGross: order?.containerMaxGross,
        isEmpty: order?.isEmpty,
        imoClasses: order?.imoClasses,
        holds: order?.holds,
        damages: damages ?? containerItem?.data.damages ?? [],
        hasSeals: hasSeals ?? order?.hasSeals,
        seals: seals ?? order?.seals,
        hasDamage: !!(damages?.length ?? containerItem?.data.damages.length),
        damagesReported: damages ?? [],
        doorDirection: order?.doorDirection,
        operationalInstructions: order?.operationalInstructions,
      },
    })

    const onFormSubmit = async (data: any) => {
      try {
        onSubmit(data)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const getStepText = () => {
      return form.watch('hasDamage') ? t('1of2', '1 of 2') : undefined
    }

    const getButtonText = () => {
      return form.watch('hasDamage') ? t('next', 'Next') : submitButtonText
    }

    const isOrderFulfilled =
      allowContainerEditAfterTallyCheckInFF && order?.status === OrderStatus.Fulfilled

    return (
      <>
        <Header
          leftRenderOption={<NavigationStackBackButton />}
          title={order?.containerNumber ?? ''}
          subTitle={order?.waggon ?? ''}
          rightRenderOption={
            <Stack flexDirection='row' gap={theme.customSpacing.xs}>
              {!isOrderFulfilled && onDelete && (
                <IconButton data-cy='delete-order-inspect-container' onClick={onDelete}>
                  <BinIcon />
                </IconButton>
              )}
              <Button
                data-cy='submit-inspect-container'
                variant='contained'
                onClick={form.handleSubmit(onFormSubmit)}
              >
                {getButtonText()}
              </Button>
            </Stack>
          }
        />

        <StepperInfo title='Inspect Container' steps={getStepText()} />
        <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
          <FormProvider {...form}>
            <InspectContainerForm containerItem={containerItem} />
          </FormProvider>
        </ContainerMobile>

        {deleteContainerDialog?.()}
      </>
    )
  },
)
