import { Grid, MenuItem, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import {
  IssueResponseDto,
  OrderResponseDto,
  OrderStatus,
  OrderUpdateResponseDto,
} from '@planning/app/api'
import { ContainerHistoryList } from '@planning/components/ContainerHistoryList'
import { DamagedContainerIcon } from '@planning/components/DamagedContainerIcon'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { HoldsDrawerButton } from '@planning/components/hold/HoldsDrawerButton'
import { emptyIndicator } from '@planning/constants'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { IssuesPageLocationState } from '@planning/pages/Issues'
import { IssueStatusChip } from '@planning/pages/Issues/Components/atoms/IssueStatusChip'
import { IssueTypeChip } from '@planning/pages/Issues/Components/atoms/IssueTypeChip'
import { OrderItemChip } from '@planning/pages/Order/components/OrderItemChip'
import { orderService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import {
  DangerousIcon,
  Group,
  HighlightedText,
  ReeferIcon,
  ThreeDotMenu,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Id } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router'
import { UpdatesLabelChip } from '../atoms/UpdatesLabelChip'

const OrderItemChips = ({ order }: { order: OrderResponseDto }) => {
  return (
    <Stack direction={'row'} spacing={0.5}>
      <OrderItemChip label={`${order.containerLength} ${order.containerType}`}></OrderItemChip>
      <OrderItemChip label={`${order.containerHeight}`}></OrderItemChip>
      <OrderItemChip label={(order.isEmpty && 'E') || 'F'}></OrderItemChip>
      {order.containerType === 'Reefer' && (
        <OrderItemChip
          label={
            <Typography
              display={'flex'}
              gap={'4px'}
              fontSize={'14px'}
              sx={{ alignItems: 'center' }}
              component={'div'}
              variant='body2'
            >
              <ReeferIcon sx={{ height: '16px', width: '16px' }} />
            </Typography>
          }
        />
      )}
      {order.imoClasses.length > 0 && (
        <OrderItemChip
          label={
            <Typography
              display={'flex'}
              gap={'4px'}
              fontSize={'14px'}
              sx={{ alignItems: 'center' }}
              component={'div'}
              variant='body2'
            >
              <DangerousIcon sx={{ height: '16px', width: '16px', marginRight: '4px' }} />
              {order.imoClasses.join(', ')}
            </Typography>
          }
        ></OrderItemChip>
      )}
      {order.content && <OrderItemChip label={order.content}></OrderItemChip>}
      {order.grossWeight && (
        <OrderItemChip
          label={`${order.grossWeight.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
        ></OrderItemChip>
      )}
      {order.vgm && (
        <OrderItemChip
          label={`VGM: ${order.vgm.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
        ></OrderItemChip>
      )}
      {order.consignee && <OrderItemChip label={order.consignee}></OrderItemChip>}
      {order.isDamaged && (
        <DamagedContainerIcon
          iconHeight={'16px'}
          iconWeight={'16px'}
          sx={{
            borderRadius: '2px',
            background: '#919eab29',
          }}
        />
      )}
    </Stack>
  )
}

export const OrderGroupListItem: FC<{
  group: Group
  item: { id: Id; content: OrderResponseDto }
  filter?: string
  hasThreeDotMenu?: boolean
  onOrderHistoryFn?: (containerNumber?: string | null) => void
  onDeleteOrderFn?: (orders: OrderResponseDto[]) => void
}> = observer(
  ({ group, item, filter, hasThreeDotMenu = false, onOrderHistoryFn, onDeleteOrderFn }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const navigate = useNavigate()

    const {
      appViewStore,
      dialogStore,
      containerEventViewStore,
      containerEventItemStore,
      issueItemStore,
      orderUpdateItemStore,
    } = usePlanningStore()

    const onResolve = async (selectedItemId: number) => {
      navigate(`${PATH_PLANNING.issues}`, {
        state: { filterByRailCarTrackPositionId: selectedItemId } as IssuesPageLocationState,
      })
    }

    const { openEditOrder } = useOrderNavigation()

    const onDeleteOrder = async (orders: OrderResponseDto[]) => {
      if (onDeleteOrderFn) {
        return onDeleteOrderFn(orders)
      } else {
        const count = orders.length
        const confirmationMessageKey =
          count === 1 ? 'orderWillBeDeletedContinue' : 'ordersWillBeDeletedContinue'
        const confirmationMessage =
          count === 1
            ? 'Order will be deleted. Continue?'
            : `The selected ${count} Orders will be deleted. Continue?`

        const hasServiceOrders = orders.some(item => item.hasServiceOrders)

        const isConfirmed = await appViewStore.setOpenConfirmDialog(
          true,
          t(confirmationMessageKey, confirmationMessage),
          t('confirmOrderDelete', 'Confirm order delete'),
          hasServiceOrders
            ? t(
                'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
                'Any associated stripping/stuffing orders will be deleted',
              )
            : undefined,
        )

        if (!isConfirmed) return

        try {
          await orderService.deleteByIds(orders.map(item => item.id))
          appViewStore.setShowAlert(
            'success',
            t('successfullyDeletedOrder', 'Successfully deleted order'),
          )
        } catch (e) {
          appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
        }
      }
    }

    const onOrderHistory = async (containerNumber?: string | null) => {
      if (onOrderHistoryFn) {
        onOrderHistoryFn(containerNumber)
      } else {
        containerEventItemStore.setContainerNumber(containerNumber!)
        await containerEventItemStore.fetch()

        dialogStore.openDialog(
          <ContainerHistoryList
            containerNumber={containerNumber}
            store={containerEventViewStore}
          />,
        )
      }
    }

    const orderIssues = computed<IssueResponseDto[]>(() => {
      const orderIssues = issueItemStore.orderIssues.filter(
        i => item.content.notResolvedIssueIds?.includes(i.id) ?? false,
      )

      return orderIssues
    }).get()

    const orderUpdates = computed<OrderUpdateResponseDto[]>(() => {
      const orderUpdates = orderUpdateItemStore.unreadOrderUpdates.filter(
        i => item.content.id === i.orderId,
      )

      return orderUpdates
    }).get()

    return (
      <Grid
        key={`grp-row-${item.id}`}
        container
        paddingX={1.5}
        paddingLeft={group.sequenced ? 7.5 : 1.5}
        height={'60px'}
        display={'flex'}
        alignItems={'center'}
        borderBottom={`1px solid ${theme.palette.grey[300]}`}
      >
        <Grid item xs={2} xl={1} textAlign={'start'}>
          <Typography component={'div'} variant='body2'>
            <HighlightedText highlight={filter} color={theme.palette.info.main}>
              {item.content.containerNumber ??
                (item.content.nonNumericOrderId ? t('nonNumeric', 'Non-numeric') : '')}
            </HighlightedText>
          </Typography>
          <Typography component={'div'} variant='mini'>
            <HighlightedText highlight={filter} color={theme.palette.info.main}>
              {`${item.content.referenceNumber ?? emptyIndicator}`}
            </HighlightedText>
          </Typography>
        </Grid>
        <Grid item xs={7} xl={8} alignSelf={'center'} textAlign={'start'}>
          <OrderItemChips order={item.content} />
        </Grid>
        <Grid textAlign={'end'} item xs={3} onClick={event => event.stopPropagation()}>
          <Grid container>
            <Grid item xs={10} xl={11} sx={{ placeContent: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  placeContent: 'flex-end',
                  gap: '4px',
                }}
              >
                {orderIssues.map(issue => (
                  <>
                    <IssueTypeChip key={issue.id} type={issue.issueType} />
                    <IssueStatusChip status={issue.issueStatus} />
                  </>
                ))}

                <FeatureFlag name='review-order-updates'>
                  {orderUpdates.length > 0 ? (
                    <UpdatesLabelChip useIcon updateCount={orderUpdates.length} />
                  ) : (
                    <></>
                  )}
                </FeatureFlag>

                <HoldsDrawerButton order={item.content} />
                <OrderStatusChip status={item.content.status} />
              </Box>
            </Grid>
            <Grid item xs={2} xl={1}>
              {hasThreeDotMenu && (
                <ThreeDotMenu>
                  <MenuItem onClick={() => openEditOrder(item.content)}>
                    {item.content.status === OrderStatus.Open
                      ? t('edit', 'Edit')
                      : t('view', 'View')}
                  </MenuItem>
                  <MenuItem onClick={() => onOrderHistory(item.content.containerNumber)}>
                    {t('history', 'History')}
                  </MenuItem>
                  <>
                    {item.content.status === OrderStatus.Open && (
                      <MenuItem onClick={() => onDeleteOrder([item.content])}>
                        {t('delete', 'Delete')}
                      </MenuItem>
                    )}
                  </>
                  <>
                    {orderIssues.length > 0 && item?.content?.railcarTrackPositionId && (
                      <MenuItem onClick={() => onResolve(item.content.railcarTrackPositionId!)}>
                        {t('resolve', 'Resolve')}
                      </MenuItem>
                    )}
                  </>
                </ThreeDotMenu>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  },
)
