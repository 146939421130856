import { Chip, Grid } from '@mui/material'
import { Stack } from '@mui/system'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CheckType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { IInspectionRailCar, RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { RailCarCard } from '../RailCarCard'
import { sortRailcars } from './sortRailcars'

interface IProps {
  railcars: IInspectionRailCar[]
  searchHighlight?: string
  operation?: CheckType
  store: RailTallyViewStore
  onSelectRailcar: (railcarId?: number) => void
  onDeleteRailcar?: (railcar: IInspectionRailCar) => void
  onReportRailcarIssue?: (railcar: IInspectionRailCar) => void
}

interface IRailcarListGridProps extends IProps {
  isPendingInspection: boolean
}

const RailcarListGrid = ({
  railcars,
  operation,
  store,
  searchHighlight,
  onSelectRailcar,
  onDeleteRailcar,
  isPendingInspection,
  onReportRailcarIssue,
}: IRailcarListGridProps) => {
  // We should move logic like this into a store
  let filteredRailcars = railcars.filter(railcar => {
    if (operation === CheckType.DischargePreparation) {
      return isPendingInspection ? !railcar.dischargeCheck : railcar.dischargeCheck
    }
    if (operation === CheckType.LoadPreparation) {
      return !railcar.loadCheck
    }
    if (operation === CheckType.DepartureCheck) {
      return !railcar.departureCheck
    }

    return !railcar.dischargeCheck && !railcar.loadCheck && !railcar.departureCheck
  })

  if (isPendingInspection) {
    filteredRailcars = filteredRailcars.filter(railcar => {
      if (store.confirmedRailcarIds.includes(railcar.railcarId)) return false

      if (
        store &&
        store.selectedRailTrackPosition &&
        store.selectedRailTrackPosition.data.checkinDate !== null
      ) {
        return railcar.railcarId !== store.selectedRailTrackPosition.data.railcarId
      }

      return railcar
    })
  }

  return (
    <Grid container marginTop={1.5} flexDirection={'column'} gap={1.5}>
      {filteredRailcars.sort(sortRailcars).map(railcar => (
        <Grid item key={`railcar-${railcar.name}`}>
          <RailCarCard
            sequence={railcar.sequence}
            containers={operation !== CheckType.LoadPreparation ? railcar.containers : []}
            operation={operation}
            railcar={railcar.name}
            hasProblem={railcar.isMissingRailcar}
            isDischargeChecked={railcar.isCheckedId}
            searchHighlight={searchHighlight}
            onSelectRailcar={() => {
              onSelectRailcar(railcar.railcarId)
            }}
            onDelete={onDeleteRailcar ? () => onDeleteRailcar(railcar) : undefined}
            onReportRailcarIssue={
              onReportRailcarIssue ? () => onReportRailcarIssue(railcar) : undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  )
}

// [RailTally] TODO: RailTally shows Load- and Discharge containers
export const InspectRailcarList: FC<IProps> = observer((props: IProps) => {
  const { t } = useTranslate()

  const allowContainerEditAfterTallyCheckInFF = useBooleanFlagValue(
    'allow-container-edit-after-tally-check-in',
    false,
  )

  const showInspectedRailcarsChip = useMemo(
    () =>
      props.operation === CheckType.DischargePreparation && allowContainerEditAfterTallyCheckInFF,
    [props.operation, allowContainerEditAfterTallyCheckInFF],
  )

  return (
    <Stack>
      <Stack flexDirection='row'>
        {showInspectedRailcarsChip && (
          <Chip
            label={t('showInspectedRailcars', 'Show inspected railcars')}
            color={props.store.toggleShowInspectedRailcar ? 'primary' : undefined}
            onClick={() =>
              props.store.setToggleShowInspectedRailcar(!props.store.toggleShowInspectedRailcar)
            }
            onDelete={
              props.store.toggleShowInspectedRailcar
                ? () => props.store.setToggleShowInspectedRailcar(false)
                : undefined
            }
          />
        )}
      </Stack>

      <RailcarListGrid {...props} isPendingInspection={!props.store.toggleShowInspectedRailcar} />
    </Stack>
  )
})
