import {
  CarrierType,
  EquipmentType,
  JobDto,
  OperationType,
  RailcarTrackPositionDto,
  WorkAreaType,
  WorkInstructionWarningReason,
  YardBlockEquipmentAssignmentDto,
} from '@operations/app/api'
import { JobStore } from '@operations/stores/JobStore'
import _ from 'lodash'

import {
  JobTypes,
  doesJobOperationTypeOrCarrierMatchesJobType,
} from '@operations/app/models/operator-pages'
import { EquipmentOperatorLandingUIStore } from '@operations/features/equipmentOperator/stores/equipment-operator-landing.ui-store'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { WorkInstructionStore } from '@operations/stores/WorkInstructionStore'
import dayjs from 'dayjs'
import { action, computed, makeObservable, observable } from 'mobx'
import { JobGroupDto } from '../models/job-group.model'
import { JobNavigationDto } from '../models/job-navigation.model'
import { isCraneEquipmentType } from '../utils'
import { EquipmentOperatorSearchUIStore } from './equipment-operator-search.ui-store'

export class EquipmentOperatorUIStore {
  hiddenJobWorkInstructionIds: number[] = []
  isContainerOperation = true
  jobType: JobTypes = JobTypes.all
  nonNumeric?: boolean
  selectedEquipmentId?: number
  railcarTrackPositions: Record<number, RailcarTrackPositionDto[]> = {}

  selectedYardBlockId?: string
  selectedBays: string[] = []
  notPlanned?: boolean
  selectedContainerSize?: number

  isMinimalisticJobView = true

  constructor(
    private jobStore: JobStore,
    private equipmentStore: EquipmentStore,
    private workInstructionStore: WorkInstructionStore,
    private equipmentOperatorSearchUIStore: EquipmentOperatorSearchUIStore,
    public equipmentOperatorLandingUIStore: EquipmentOperatorLandingUIStore,
  ) {
    makeObservable(this, {
      jobType: observable,
      hiddenJobWorkInstructionIds: observable,
      isContainerOperation: observable,
      nonNumeric: observable,
      notPlanned: observable,
      selectedEquipmentId: observable,
      railcarTrackPositions: observable,
      selectedYardBlockId: observable,
      selectedBays: observable,
      selectedContainerSize: observable,
      isMinimalisticJobView: observable,

      closeSearchIfFinishingLastNotification: action,
      hideJob: action,
      loadJobs: action,
      selectEquipment: action,
      setIsContainerOperation: action,
      setJobType: action,
      setNonNumeric: action,
      setNotPlanned: action,
      setOperationType: action,
      setYardBlockId: action,
      setSelectedBays: action,
      setWagonWeights: action,
      showJob: action,
      setSelectedContainerSize: action,
      setMinimalisticJobView: action,

      jobs: computed,
      carrierTypeFilteredJobsNavigationDto: computed,
      equipment: computed,
      filteredJobs: computed,
      subtitle: computed,
      hasJobNavigation: computed,
      selectedYardBlockJobs: computed,
      typeOfSelectedEquipment: computed,
      yardBlocksAssignments: computed,
    })
  }

  loadJobs = async () => {
    await this.jobStore.loadJobsByEquipmentId(this.selectedEquipmentId)
  }

  public async selectEquipment(id?: number) {
    if (this.selectedEquipmentId !== id) {
      this.selectedEquipmentId = id
      await this.loadJobs()
    }
  }

  public setIsContainerOperation(value: boolean) {
    if (this.isContainerOperation !== value) {
      this.isContainerOperation = value
    }
  }

  public setNonNumeric(value?: boolean) {
    if (this.nonNumeric !== value) {
      this.nonNumeric = value

      if (value) {
        this.selectedYardBlockId = undefined
        this.selectedBays = []

        if (this.jobType === JobTypes.internal || this.jobType === JobTypes.service)
          this.initializerJobType()
      }
    }
  }

  public setNotPlanned(value?: boolean) {
    if (this.notPlanned !== value) {
      this.notPlanned = value
      this.selectedYardBlockId = undefined
      this.selectedBays = []
      this.nonNumeric = false
      this.initializerJobType()
    }
  }

  public setJobType(jobType: JobTypes): void {
    if (jobType !== this.jobType) {
      this.jobType = jobType
      this.equipmentOperatorLandingUIStore.selectOperationType(null)

      if (this.jobType === JobTypes.internal || this.jobType === JobTypes.service) {
        this.nonNumeric = false
      }
    }
  }

  public setOperationType(operationType?: OperationType): void {
    this.equipmentOperatorLandingUIStore.selectOperationType(operationType ?? null)
  }

  public async setDefaultTypeAndArea() {
    const assignedYardBlock = this.yardBlocksAssignments.find(y =>
      y.equipments.some(e => e.isPlanned && e.id === this.selectedEquipmentId),
    )

    this.setYardBlockId(assignedYardBlock?.yardBlockId)

    const yardEquipmentWorkArea = this.equipment.workAreaEquipments?.find(
      x => x.yardBlockId === assignedYardBlock?.yardBlockId,
    )

    if (yardEquipmentWorkArea) {
      this.setSelectedBays(yardEquipmentWorkArea.assignedBays)
    }

    const carrierEquipmentWorkArea = this.equipment.workAreaEquipments?.find(
      x =>
        x.isInOperation &&
        (x.workAreaType === WorkAreaType.Crane || x.workAreaType === WorkAreaType.Train),
    )

    if (carrierEquipmentWorkArea) {
      this.setJobType(
        carrierEquipmentWorkArea.workAreaType === WorkAreaType.Crane
          ? JobTypes.vessel
          : JobTypes.train,
      )
    }
  }

  public async setYardBlockId(id?: string) {
    if (this.selectedYardBlockId !== id) {
      this.selectedYardBlockId = id
      this.selectedBays = []
      this.notPlanned = false
      this.nonNumeric = false
    }
  }

  public async setSelectedBays(bays: string[]) {
    this.selectedBays = bays
  }

  public async setSelectedContainerSize(size?: number) {
    this.selectedContainerSize = size
  }

  public async setWagonWeights(carrierVisitId: number) {
    this.railcarTrackPositions[carrierVisitId] =
      await this.workInstructionStore.getRailcarTrackPositions(carrierVisitId)
  }

  public hideJob(workInstructionId?: number) {
    if (workInstructionId) {
      this.hiddenJobWorkInstructionIds = [...this.hiddenJobWorkInstructionIds, workInstructionId]
    }
  }

  public showJob(workInstructionId?: number) {
    this.hiddenJobWorkInstructionIds = this.hiddenJobWorkInstructionIds.filter(
      x => x !== workInstructionId,
    )
  }

  public selectVesselIfCraneOperator() {
    if (this.jobs.length === 0) {
      return
    }
    if (isCraneEquipmentType(this.typeOfSelectedEquipment)) {
      this.setJobType(JobTypes.vessel)
    }
  }

  public closeSearchIfFinishingLastNotification(workInstructionId: number) {
    if (
      this.equipmentOperatorSearchUIStore.isSearchOpen &&
      this.equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds?.includes(
        workInstructionId,
      ) &&
      this.searchedAndFilteredJobs.flatMap(x => x.jobs).length === 0
    ) {
      this.equipmentOperatorSearchUIStore.toggleSearch()
    }
  }

  public setMinimalisticJobView(value: boolean) {
    if (this.isMinimalisticJobView !== value) this.isMinimalisticJobView = value
  }

  public get jobs() {
    const jobs = this.equipmentOperatorLandingUIStore.selectedVesselVisit
      ? this.jobStore.jobAndYardBlocksDto.jobs.filter(
          j => j.carrierVisit?.id === this.equipmentOperatorLandingUIStore.selectedVesselVisit!.id,
        )
      : this.jobStore.jobAndYardBlocksDto.jobs

    const confirmedJobs = !isCraneEquipmentType(this.typeOfSelectedEquipment)
      ? filterPlannedJobs(jobs)
      : jobs

    return orderJobsWithDeadline(confirmedJobs).filter(
      x => !this.hiddenJobWorkInstructionIds.includes(x.workInstructionId),
    )
  }

  public get yardBlocksAssignments() {
    return this.jobStore.jobAndYardBlocksDto.yardBlockEquipmentAssignments
  }

  public get equipment() {
    return this.equipmentStore.items.find(x => x.id === this.selectedEquipmentId)!
  }

  public get typeOfSelectedEquipment() {
    return this.equipment?.equipmentType
  }

  public get carrierTypeFilteredJobsNavigationDto(): JobNavigationDto {
    const dischargeAmount = this.jobsFilteredByTypeAndSizeAndYardBlock.filter(
      x =>
        (!this.nonNumeric || !x.container?.containerNumber) &&
        x.operationType === OperationType.Inbound,
    ).length

    const loadAmount = this.jobsFilteredByTypeAndSizeAndYardBlock.filter(
      x =>
        (!this.nonNumeric || !x.container?.containerNumber) &&
        x.operationType === OperationType.Outbound,
    ).length

    return {
      dischargeAmount,
      loadAmount,
    }
  }

  public get filteredJobs(): JobGroupDto[] {
    const filteredJobs = this.jobsFilteredByTypeAndSizeAndYardBlock.filter(
      x =>
        (!this.nonNumeric || !x.container?.containerNumber) &&
        (!this.equipmentOperatorLandingUIStore.selectedOperationType ||
          x.operationType === this.equipmentOperatorLandingUIStore.selectedOperationType),
    )

    return groupAndSortJobs(filteredJobs, this.jobType)
  }

  public get selectedYardBlockJobs() {
    return this.jobs.filter(x => this.isJobWithinTheSelectedYardBlockAndBays(x))
  }

  public get searchedAndFilteredJobs() {
    if (this.equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds) {
      return createEmptyGroupToShowAllJobs(
        this.jobs.filter(x =>
          this.equipmentOperatorSearchUIStore.selectedJobWorkInstructionIds?.includes(
            x.workInstructionId,
          ),
        ),
      )
    }

    if (this.equipmentOperatorSearchUIStore.searchText) {
      const searchedContainerLowerCase =
        this.equipmentOperatorSearchUIStore.searchText.toLowerCase()

      const searchedContainerWithoutSpace = searchedContainerLowerCase.replace(/\s/g, '')

      return createEmptyGroupToShowAllJobs(
        this.jobs.filter(
          x =>
            x.container?.containerNumber?.toLowerCase()?.includes(searchedContainerWithoutSpace) ||
            x.order?.referenceNumber?.toLowerCase()?.includes(searchedContainerLowerCase) ||
            x.container?.portOfDischarge?.toLowerCase()?.includes(searchedContainerLowerCase) ||
            x.carrierVisit?.carrierName?.toLowerCase()?.includes(searchedContainerLowerCase),
        ),
      )
    }

    return []
  }

  public get hasJobNavigation() {
    return (
      this.typeOfSelectedEquipment !== EquipmentType.Tt &&
      this.typeOfSelectedEquipment !== EquipmentType.Sts
    )
  }

  public get subtitle() {
    if (!this.equipment || isCraneEquipmentType(this.equipment?.equipmentType)) {
      return ''
    }

    const cranes = _(
      this.equipment.workAreaEquipments?.filter(x => x.isInOperation && x.crane).map(x => x.crane),
    )
      .uniq()
      .sort()
      .join(', ')
      .trim()

    if (this.equipment?.equipmentType === EquipmentType.Tt) {
      return cranes
    }

    const yardBlocks = _(this.equipment.yardBlocks?.map(x => x.name).filter(x => !!x))
      .uniq()
      .sort()
      .join(', ')
      .trim()

    if (yardBlocks.length && cranes.length) return `${yardBlocks} / ${cranes}`

    return yardBlocks.length ? yardBlocks : cranes
  }

  private get jobsFilteredByJobType() {
    return this.jobs.filter(
      x =>
        !this.hasJobNavigation ||
        doesJobOperationTypeOrCarrierMatchesJobType(
          this.jobType,
          x.operationType,
          x.carrierVisit?.type,
        ),
    )
  }

  private get jobsFilteredByTypeAndSizeAndYardBlock() {
    return this.jobsFilteredByJobType.filter(
      x =>
        !this.hasJobNavigation ||
        ((!this.selectedContainerSize || x.container?.length === this.selectedContainerSize) &&
          this.isJobWithinTheSelectedYardBlockAndBays(x)),
    )
  }

  private isJobWithinTheSelectedYardBlockAndBays(job: JobDto) {
    if (this.notPlanned) {
      return isJobNotPlanned(job)
    }

    if (!this.selectedYardBlockId) {
      return true
    }

    const yardBlock = this.yardBlocksAssignments.find(
      x => x.yardBlockId === this.selectedYardBlockId,
    )

    return (
      isJobWithinYardBlock(job, yardBlock) &&
      (this.selectedBays.length === 0 || isJobWithinYardBlockBays(job, this.selectedBays))
    )
  }

  private initializerJobType() {
    if (this.jobType !== JobTypes.all) {
      this.jobType = JobTypes.all
    }
  }
}

export const isJobNotPlanned = (job: JobDto) =>
  !job.origin.yardBlockName &&
  !job.destination.yardBlockName &&
  !job.container?.plannedDischargeYardBlockId

export const isJobWithinYardBlock = (job: JobDto, yardBlock?: YardBlockEquipmentAssignmentDto) =>
  job.origin.yardBlockName === yardBlock?.yardBlockName ||
  job.destination.yardBlockName === yardBlock?.yardBlockName ||
  job.container?.plannedDischargeYardBlockId === yardBlock?.yardBlockId

export const isJobWithinYardBlockBays = (job: JobDto, bays: string[]) =>
  bays.some(x => job.origin.yardBays?.includes(x)) ||
  bays.some(x => job.destination.yardBays?.includes(x))

export const groupAndSortJobs = (jobs: JobDto[], selectedJobType: JobTypes) => {
  if (selectedJobType === JobTypes.all) {
    return [
      ...groupAndSortJobsByTruckVisit(jobs.filter(j => j.carrierVisit?.type === CarrierType.Truck)),
      ...groupAndSortJobsByCargoGroup(jobs.filter(j => j.carrierVisit?.type !== CarrierType.Truck)),
    ]
  }

  if (selectedJobType === JobTypes.truck) {
    return groupAndSortJobsByTruckVisit(jobs)
  } else {
    return groupAndSortJobsByCargoGroup(jobs)
  }
}

const groupAndSortJobsByTruckVisit = (jobs: JobDto[]) => {
  const jobGroups = jobs.reduce((groups: JobGroupDto[], job) => {
    const carrierVisitId = job.carrierVisit?.id

    const groupName = job.carrierVisit?.carrierName ?? ''
    const existingGroup = groups.find(group => group.identifier === carrierVisitId)

    if (existingGroup) {
      existingGroup.jobs.push(job)
    } else {
      groups.push({ identifier: carrierVisitId!, groupName, jobs: [job] })
    }

    return groups
  }, [])

  return jobGroups.sort((a, b) => {
    const aAta = new Date(a.jobs[0].carrierVisit?.ata ?? '')
    const bAta = new Date(b.jobs[0].carrierVisit?.ata ?? '')

    return aAta.getTime() - bAta.getTime()
  })
}

const groupAndSortJobsByCargoGroup = (jobs: JobDto[]) => {
  const jobGroups = jobs.reduce((groups: JobGroupDto[], job) => {
    const groupId = job.grouping?.groupId ?? 0

    const groupName = job.grouping?.groupName ?? ''
    const existingGroup = groups.find(group => group.identifier === groupId)

    if (existingGroup) {
      existingGroup.jobs.push(job)
    } else {
      groups.push({ identifier: groupId, groupName, jobs: [job] })
    }

    return groups
  }, [])

  const sortedJobGroups = jobGroups.map(group => ({
    identifier: group.identifier,
    groupName: group.groupName,
    jobs: handleGroupedJobsSorting(group.jobs),
  }))

  return sortedJobGroups.sort((a, b) => {
    if (a.groupName === '') return 1
    if (b.groupName === '') return -1

    return a.groupName.localeCompare(b.groupName)
  })
}

export const handleGroupedJobsSorting = (jobs: JobDto[]): JobDto[] => {
  const sequenceSortedJobs: JobDto[] = _(jobs)
    .filter(x => !!x.grouping?.sequence)
    .orderBy(x => x.grouping?.sequence)
    .value()

  const noSequenceJobs = jobs.filter(
    j => !sequenceSortedJobs.some(x => x.workInstructionId === j.workInstructionId),
  )

  return [...sequenceSortedJobs, ...noSequenceJobs]
}

const createEmptyGroupToShowAllJobs = (jobs: JobDto[]): JobGroupDto[] => {
  return [
    {
      identifier: 0,
      groupName: '',
      jobs,
    },
  ]
}

export const filterPlannedJobs = (jobs: JobDto[]) => {
  const acceptablePlannedJobs: WorkInstructionWarningReason[] = [
    WorkInstructionWarningReason.RequiresReservation,
  ]

  return jobs.filter(
    job =>
      !job.isPlanned || (job.warningReason && acceptablePlannedJobs.includes(job.warningReason)),
  )
}

export const orderJobsWithDeadline = (jobs: JobDto[]) => {
  //First add ordered all jobs with one hour to dead line
  const orderedJobs: JobDto[] = orderJobs(jobsWithDeadline(jobs))

  //Then add ordered rest jobs
  const otherJobs = jobs.filter(
    j => !orderedJobs.some(x => x.workInstructionId === j.workInstructionId),
  )
  const orderedOtherJobs = orderJobs(otherJobs)

  orderedJobs.push(...orderedOtherJobs)

  return orderedJobs
}

export const jobsWithDeadline = (jobs: JobDto[]) => {
  return _(jobs)
    .filter(job => !!job.deadline && dayjs(job.deadline).diff(new Date(), 'minutes') <= 60)
    .orderBy(['workInstruction.deadline', 'id'])
    .value()
}

export const orderJobs = (jobs: JobDto[]) => {
  //First show all discharge
  const orderedList = jobs.filter(x => x.operationType === OperationType.Inbound)

  //Then all load ordered
  const outboundJobsInWorkQueue = jobs.filter(
    x => x.operationType === OperationType.Outbound && !!x.workQueueId,
  )

  if (outboundJobsInWorkQueue.length) {
    const workAreaGroup = _(outboundJobsInWorkQueue)
      .sortBy(x => x.destinationWorkAreaId)
      .groupBy(x => x.destinationWorkAreaId)
      .map(group => _.orderBy(group, x => x.workQueueOrder))
      .value()

    if (workAreaGroup.length) {
      const outboundJobsOrdered = _(workAreaGroup)
        .flatMap(group => _.map(group, (job, index) => ({ index, job })))
        .orderBy(a => a.index)
        .map(a => a.job)
        .value()

      orderedList.push(...outboundJobsOrdered)
    }
  }

  //Then all load not in queues
  orderedList.push(
    ...jobs.filter(x => x.operationType === OperationType.Outbound && !x.workQueueId),
  )

  //Then all service
  orderedList.push(...jobs.filter(x => x.operationType === OperationType.Service))

  //Then all internal
  orderedList.push(...jobs.filter(x => x.operationType === OperationType.Internal))

  return orderedList
}
