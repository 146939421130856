import { Box, Button, Card, IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CheckType } from '@planning/app/api'
import { formatRailcarName } from '@planning/utils/railcar-utils'
import { useTranslate } from '@tolgee/react'
import { BinIcon, GeneralIcon, HighlightedText, ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { WarningIcon } from 'modules/ui/src/theme/overrides/CustomIcons'
import { FC } from 'react'
import { checkedSuffixText } from '../InspectRailcar'

interface Props {
  containers: {
    number: string
    hasIssue: boolean | undefined
  }[]
  railcar: string
  hasProblem: boolean
  isDischargeChecked: boolean
  sequence: number
  operation?: CheckType
  searchHighlight?: string
  onSelectRailcar: () => void
  onDelete?: () => void
  onReportRailcarIssue?: () => void
}

const ContainerChip: FC<{ containerNumber: string; hasIssue: boolean | undefined }> = ({
  containerNumber,
  hasIssue,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  return (
    <Box
      sx={{
        display: 'inline-block',
        p: '.25rem .75rem',
        bgcolor: theme.palette.grey[200],
        fontSize: '14px',
        borderRadius: '8px',
        mr: '6px',
        mb: '6px',
      }}
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {hasIssue && (
          <Tooltip title={t('containerHasIssue', 'Container has issue')}>
            <span style={{ display: 'flex' }}>
              <WarningIcon color='warning' />
            </span>
          </Tooltip>
        )}
        {!hasIssue && <GeneralIcon />}
        <Typography variant='subtitle2'>{containerNumber}</Typography>
      </Stack>
    </Box>
  )
}

export const RailCarCard: FC<Props> = ({
  containers,
  railcar,
  hasProblem,
  isDischargeChecked,
  sequence,
  searchHighlight,
  operation,
  onDelete,
  onSelectRailcar,
  onReportRailcarIssue,
}) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const allowContainerEditAfterTallyCheckInFF = useBooleanFlagValue(
    'allow-container-edit-after-tally-check-in',
    false,
  )

  const isDischargeAndChecked =
    operation === CheckType.DischargePreparation &&
    isDischargeChecked &&
    allowContainerEditAfterTallyCheckInFF

  return (
    <Card
      sx={{
        padding: '1rem',
      }}
    >
      <Stack direction={'row'} spacing={2} alignItems={'center'} mb={theme.customSpacing.s}>
        <Box
          sx={{
            display: 'inline-block',
            p: '.5rem .75rem',
            bgcolor: theme.palette.grey[900],
            color: '#fff',
            borderRadius: '8px',
          }}
        >
          {sequence}
        </Box>
        <Typography variant='h2'>
          <HighlightedText color={theme.palette.info.main} highlight={searchHighlight}>
            {formatRailcarName(railcar)}
          </HighlightedText>
        </Typography>
        {hasProblem && <WarningIcon color='warning' />}
      </Stack>

      <Box alignItems={'center'}>
        {containers.map(item => (
          <ContainerChip key={item.number} containerNumber={item.number} hasIssue={item.hasIssue} />
        ))}
      </Box>
      {!hasProblem && (
        <Stack direction={'row'} spacing={2} alignItems={'center'} mt={theme.customSpacing.s}>
          <Button
            variant='contained'
            size='large'
            sx={{ width: '100%', mt: '1rem' }}
            onClick={onSelectRailcar}
            data-cy={`check-or-edit-railcar-${railcar}-button`}
          >
            {isDischargeAndChecked
              ? t('edit', 'Edit')
              : `${t('check', 'Check')} ${checkedSuffixText(operation, t)}`}
          </Button>

          {onDelete && !isDischargeAndChecked && (
            <IconButton
              onClick={onDelete}
              sx={{
                bgcolor: theme.palette.grey[200],
              }}
            >
              <BinIcon />
            </IconButton>
          )}
          {onReportRailcarIssue && (
            <ThreeDotMenu>
              <MenuItem onClick={onReportRailcarIssue}>
                {t('reportAnIssue', 'Report an issue')}
              </MenuItem>
            </ThreeDotMenu>
          )}
        </Stack>
      )}
    </Card>
  )
}
