import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { UpsertContainerCommand } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { validateContainerNumber, validateIsoCode } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'

interface IProps {
  containerNumber: string
  onSubmit: (data: UpsertContainerCommand) => Promise<void> | void
}

export const AddContainerInfoForm: FC<IProps> = observer(({ onSubmit, containerNumber }) => {
  const { t } = useTranslate()
  const { isoCodeMappingInputStore } = usePlanningStore()

  const customIsoMappingFf = useBooleanFlagValue('custom-iso-mapping', false)

  const { register, handleSubmit, watch, control, formState } = useForm<UpsertContainerCommand>({
    defaultValues: {
      number: containerNumber.toUpperCase(),
      isoCode: '',
      operator: '',
    },
  })

  const { errors } = formState

  const containerNumberErrorText = (errors: FieldErrors) => {
    const containerNumberError = errors.number
    if (containerNumberError) {
      const errorType = containerNumberError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'validate')
        return `${t('mustMatchPattern', 'Must match pattern')}: ABCU1234567`

      return containerNumberError.message?.toString()
    }
  }

  const containerIsoCodeErrorText = (errors: FieldErrors) => {
    const isoCodeError = errors.isoCode
    if (isoCodeError) {
      const errorType = isoCodeError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

      return isoCodeError.message?.toString()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ m: '1rem 0' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              {t('addContainerInformation', 'Add Container Information')}
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              data-cy='container-number-field'
              fullWidth
              label={`${t('containerNumber', 'Container Number')} *`}
              variant='outlined'
              type='text'
              {...register('number', {
                required: true,
                validate: v => !!v && validateContainerNumber(v),
              })}
              onChange={event => (event.target.value = event.target.value.toUpperCase())}
              name='number'
              error={!!errors.number}
              helperText={containerNumberErrorText(errors)}
            />
          </Grid>

          {!customIsoMappingFf && (
            <Grid item sm={6} xs={12}>
              <TextField
                data-cy='container-iso-code-field'
                fullWidth
                label={`${t('isoCode', 'ISO Code')}*`}
                variant='outlined'
                type='text'
                {...register('isoCode', {
                  required: true,
                  validate: v => !!v && validateIsoCode(v),
                })}
                onChange={event => (event.target.value = event.target.value.toUpperCase())}
                name='isoCode'
                error={!!errors.isoCode}
                helperText={containerIsoCodeErrorText(errors)}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={customIsoMappingFf ? 6 : 12}>
            <TextField
              data-cy='container-operator-field'
              fullWidth
              label={`${t('operator', 'Operator')}`}
              variant='outlined'
              type='text'
              {...register('operator')}
              name='operator'
            />
          </Grid>

          {customIsoMappingFf && (
            <Grid item xs={12}>
              {isoCodeMappingInputStore.renderIsoCodeMappingInput?.({
                label: `${t('isoCode', 'ISO Code')} *`,
                fullWidth: true,
                variant: 'outlined',
                type: 'text',
                uppercase: true,
                formState: formState,
                name: 'isoCode',
                required: true,
                control: control,
                watch: watch,
              })}
            </Grid>
          )}

          <Grid item xs={12} textAlign='right'>
            <Button variant='contained' type='submit' data-cy='create-new-order-btn'>
              {t('createNewOrder', 'Create new order')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
})
