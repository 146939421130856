import { Box, FormControlLabel, Stack, Switch, Tooltip, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { EquipmentType } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { ContainerMobile, IconButton, SearchIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { HeaderWithLogout } from '../components/Header/HeaderWithLogout'
import { JobList } from '../components/JobList/JobList'
import { JobsFilter } from '../components/JobsFilter/JobsFilter'

export const EquipmentOperatorJobListContainer = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { equipmentOperatorUIStore, equipmentOperatorSearchUIStore } = useOperationsStore()

  const handleSearch = () => {
    equipmentOperatorSearchUIStore.toggleSearch()
  }

  return (
    <>
      <HeaderWithLogout
        title={equipmentOperatorUIStore.equipment?.name}
        subtitle={equipmentOperatorUIStore.subtitle}
        action={
          <>
            {equipmentOperatorUIStore.typeOfSelectedEquipment !== EquipmentType.Tt &&
              equipmentOperatorUIStore.jobs.length > 0 && (
                <Tooltip title={t('search', 'Search')}>
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              )}
          </>
        }
        showCorrectPosition
        showSelectEquipment
      />
      <ContainerMobile
        sx={{
          paddingY: theme.customSpacing.m,
          backgroundColor: theme.palette.grey[200],
          minHeight: 'calc(100vh - 65px)',
        }}
      >
        <Stack gap={theme.customSpacing.m}>
          <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='subtitle1'>
              {t('workInstructions', 'Work instructions')}
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  checked={equipmentOperatorUIStore.isMinimalisticJobView}
                  onChange={(_, checked) => {
                    equipmentOperatorUIStore.setMinimalisticJobView(checked)
                  }}
                />
              }
              label={t('minimalisticJobView', 'Minimalistic job view')}
            />
          </Stack>

          {equipmentOperatorUIStore.hasJobNavigation && (
            <JobsFilter store={equipmentOperatorUIStore} />
          )}

          <JobList jobGroups={equipmentOperatorUIStore.filteredJobs} />

          {equipmentOperatorUIStore.selectedEquipmentId &&
            equipmentOperatorUIStore.filteredJobs.length === 0 && (
              <Box
                sx={{
                  height: '50vh',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='subtitle1' color='secondary'>
                  {t('noWorkAssignmentsToDisplay', 'No work assignments to display at this time')}
                </Typography>
                <Typography variant='subtitle1' color='secondary'>
                  {t('pleaseSearchForDifferentCriteria', 'Please search for different criteria')}
                </Typography>
              </Box>
            )}
        </Stack>
      </ContainerMobile>
    </>
  )
})
