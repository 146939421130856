import { Checkbox, ListItemText, MenuItem } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

interface Props {
  bays: string[]
  selectedBays: string[]
  onSelectBays: (bays: string[]) => void
}
export const JobFilterBays = observer(({ bays, selectedBays, onSelectBays }: Props) => {
  const { t } = useTranslate()

  if (!bays.length) return <></>

  const value = useMemo(() => {
    if (!selectedBays?.length) return undefined

    if (selectedBays.length === 1) return `${t('bay', 'Bay')} ${selectedBays[0]}`

    const sortedBays = [...selectedBays].sort()

    const ranges = sortedBays.reduce((result: { firstBay: string; lastBay?: string }[], bay) => {
      const lastRange = result.at(-1)

      if (!lastRange || +bay > +(lastRange.lastBay ?? lastRange.firstBay) + 1) {
        result.push({ firstBay: bay })
      } else {
        lastRange.lastBay = bay
      }

      return result
    }, [])

    const stringRanges = ranges
      .slice(0, 3)
      .map(({ firstBay, lastBay }) => (lastBay ? `${firstBay}-${lastBay}` : firstBay))

    return `${t('bays', 'Bays')} ${stringRanges.join(', ')}${ranges.length > 3 ? '...' : ''}`
  }, [selectedBays, t])

  const handleSelect = (bay: string) => {
    if (selectedBays?.includes(bay)) {
      onSelectBays(selectedBays.filter(x => x !== bay))
    } else {
      onSelectBays([...selectedBays, bay])
    }
  }

  const reset = () => {
    onSelectBays([])
  }

  return (
    <ChipWithOptions
      value={value}
      label={t('bay', 'Bay')}
      onReset={reset}
      multiple
      options={bays.map(bay => (
        <MenuItem
          key={bay}
          selected={selectedBays.includes(bay)}
          value={bay}
          onClick={() => {
            handleSelect(bay)
          }}
        >
          <Checkbox checked={selectedBays.includes(bay)} size='small' />
          <ListItemText primary={bay} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      ))}
    />
  )
})
