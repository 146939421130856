import { Box } from '@mui/system'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { CheckType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IControlledIsoCodeMappingInputProps } from '@planning/pages/Order/stores/IsoCodeMappingInputStore'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { TallymanV2ViewStore } from '../../../../rt-stores/tallyman/TallymanV2ViewStore'
import { SelectCarrierAndOperation } from '../../SelectCarrierAndOperation'
import { VesselVisitOperation } from '../VesselSelectionStepper'
import { CarrierTypeSelection } from './CarrierTypeSelection'

interface IProps {
  store: TallymanV2ViewStore
  onSelectVesselOperation: (op: VesselVisitOperation) => void
  onSelectTrainOperation: (op: CheckType) => void
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}

export const WorkAssignmentsSelection: FC<IProps> = observer(
  ({ store, onSelectVesselOperation, onSelectTrainOperation, renderIsoCodeMappingInput }) => {
    const { tenantStore, isoCodeMappingInputStore } = usePlanningStore()
    const isTallyRailViewFeatureEnabled = useBooleanFlagValue('tally-rail-view', false)
    const skipRailTally = tenantStore.skipRailTally || !isTallyRailViewFeatureEnabled
    const skipVesselTally = tenantStore.skipVesselTally

    if (!store.railTallyStore || !store.vesselTallyStore) return <></>

    isoCodeMappingInputStore.renderIsoCodeMappingInput = renderIsoCodeMappingInput

    const { vesselTallyStore, railTallyStore } = store

    return (
      <>
        {!skipRailTally && !skipVesselTally && (
          <Box paddingY={0.5}>
            <CarrierTypeSelection
              carrierType={store.selectedCarrierType}
              onChange={store.selectCarrierType}
              trainCount={railTallyStore.railVisits.length}
              vesselCount={vesselTallyStore.vesselVisits.length}
            />
          </Box>
        )}

        <SelectCarrierAndOperation
          store={store}
          selectedCarrierType={store.selectedCarrierType}
          onSelectVesselOperation={onSelectVesselOperation}
          onSelectTrainOperation={onSelectTrainOperation}
        />
      </>
    )
  },
)
